// Extra small devices (portrait phones, less than 576px)

@media
  (max-width : 575.98px) {
  .off-canvas-nav {
    right : -100vw;
    width : 100vw;
  }

  .green-box {
    padding   : 32px;
    word-wrap : break-word;
  }

  .header-boxes {
    .header-box {
      padding : 32px;
    }
  }

  .half-screen-slider {
    position : relative;

    .images-slider {
      height : 350px;

      .image-slide {
        height : 350px;
      }
    }
  }

  h2 {
    color     : $black;
    font-size : 28px;
  }

  .getuigenissen-slider {
    .getuigenis {
      display         : flex;
      align-items     : center;
      flex            : auto;
      flex-direction  : column;
      justify-content : center;
    }
  }

  footer {
    .sub-footer {
      align-items    : flex-start;
      flex-direction : column;
    }
  }
}

// Small devices (landscape phones, less than 768px)

@media
  (max-width : 767.98px) {
  .header-cta {
    h1 {
      color       : $white;
      font-weight : bold;
    }

    .overlay {
      background-color : transparentize($black, .6);
    }
  }
}

// Medium devices (tablets, less than 992px)

@media
  (max-width : 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right : 15px;
    padding-left  : 15px;
  }

  .half-screen-slider {
    .images-slider {
      position : relative;
      bottom   : 0;
      width    : 100vw;
    }
  }
}

// Large devices (desktops, less than 1200px)

@media
  (max-width : 1199.98px) {
}
