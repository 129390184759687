// ==========[ OFF CANVAS MENU ]==========

.no-scrolling {
  height   : 100%;
  overflow : hidden;
}

.navbar-toggler {
  display         : flex;
  color           : $black;
  align-items     : center;
  justify-content : flex-start;

  p {
    color : $black;
  }
}

.off-canvas-nav {
  z-index         : 9999;
  display         : flex;
  position        : fixed;
  top             : 0;
  right           : -520px;
  bottom          : 0;
  width           : 520px;
  padding         : 40px;
  border-left     : 2px solid $grey-light;
  background      : $white;
  align-items     : flex-start;
  flex-direction  : column;
  justify-content : space-between;
  transition      : right 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  section {
    display         : flex;
    width           : 100%;
    align-items     : flex-start;
    flex-direction  : column;
    justify-content : flex-start;

    .burger-menu {
      align-self : flex-end;
    }
  }

  ul {
    margin  : 0;
    padding : 0;

    li {
      margin     : 20px 0;
      list-style : none;

      a {
        color       : $black;
        font-size   : 16px;
        font-weight : 700;

        &.active {
          color       : red;
          font-weight : 700;
        }

        &:hover {
          text-decoration : none;
        }
      }
    }
  }

  &.active {
    display    : flex;
    right      : 0;
    box-shadow : -10px 0px 80px 0px rgba(0,0,0,0.25);
  }
}

.fullscreen-overlay {
  z-index          : 9998;
  position         : absolute;
  top              : 0;
  left             : 0;
  width            : 0;
  height           : 0;
  background-color : transparentize(#000000, 1);
  transition       : background-color 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  &.active {
    top              : 0;
    right            : 0;
    bottom           : 0;
    left             : 0;
    width            : auto;
    height           : auto;
    background-color : transparentize(#000000, .5);
  }
}

// ==========[ BUTTONS ]==========

.btn {
  padding       : 14px 20px;
  border        : 0;
  border-radius : 999px;
  font-size     : 1em;
  font-weight   : bold;
}

.btn-primary {
  color            : $white;
  background-color : $green;

  &:hover {
    color            : $white;
    background-color : darken($green, 8%);
  }
}

.btn-secondary {
  color            : $black;
  background-color : $white;

  &:hover {
    color            : $black;
    background-color : darken($white, 10%);
  }
}

.btn-toggler {
  color            : $black;
  border           : 2px solid $black;
  background-color : transparent;

  &:hover {
    color            : $grey-light;
    background-color : $black;
  }
}

// ==========[ HELPER ]==========

.c-green {
  color : $green !important;
}

.green-box {
  padding       : 48px 38px;
  border        : 2px solid $green;
  border-radius : 50px;
  h3{
    line-height: 30px;
    a {
      color           : $green;
      text-decoration : underline;
      font-weight     : bold;
  
      &:hover {
        color           : darken($green, 10%);
        text-decoration : none;
      }
    }
  }
}

.social {
  color     : $black;
  font-size : 20px;

  &:hover {
    color           : $green;
    text-decoration : none;
  }
}

// ==========[ MODALS ]==========

.modal-dialog {
  max-width : 1000px;

  .modal-content {
    padding          : 50px;
    border-radius    : 0;
    background-color : transparentize($green, .05);
    border-radius : 50px;

    .modal-header {
      border : 0;

      .modal-title {
        color     : $white;
        font-size : 32px;
      }

      .close {
        color   : $white;
        font-size: 40px;
        opacity: 1;
        right: 0;
      }
    }

    .modal-body {
      padding-top : 0;

      .cms {
        color : $white;
        p, span {
            color: $white;
            span {
                color: $white !important;
            }
        }
      }

      .image {
          width: 100%;
          display: flex;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          height: auto;
          padding-top: 100%;
      }
    }

    .modal-footer {
      border          : 0;
      justify-content : flex-start;

      .btn-modal, .btn-modal:link, .btn-modal:active, .btn-modal:visited, .btn-modal:hover {
          background: $white !important;
          color: $green;
          padding: 16px 21px;
          text-decoration: none;
      }
    }
  }
}

.btn-modal {
  @include     quart-transition(400ms);
  color      : $green;
  box-shadow : 3px 6px 9px rgba(0, 0, 0, .16);

  &:hover {
    color      : darken($green, 8%);
    box-shadow : none;
    transform  : translate(0, 2px);
  }
}
