// ==========[ FONTS ]==========

$ff-myriad-pro: myriad-pro, sans-serif;

// ==========[ COLORS ]==========

$white      : #FFFFFF;
$grey-light : #FAFAFA;
$grey       : #EEEEEE;
$grey-dark  : #939598;
$black      : #6C7379;
$green      : #76B214;

// ==========[ MIXINS ]==========

@mixin softshadow() {
    box-shadow: 0px 10px 30px -5px rgba(0,0,0,.3);
  }
  
  @mixin quart-transition($duration) {
    transition: all $duration cubic-bezier(0.770, 0.000, 0.175, 1.000);
  }
