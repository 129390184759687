// ==========[ GENERAL ]==========

* {
  font-feature-settings   : "kern" 1;
  font-family             : $ff-myriad-pro;
  font-kerning            : normal;
  -webkit-font-smoothing  : antialiased;
  -moz-osx-font-smoothing : grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin  : 0;
  padding : 0;
}

h1 {
  color       : $black;
  font-size   : 40px;
  line-height : 50px;

  span {
    color       : $white;
    font-weight : bold;
  }
}

h2 {
  color     : $black;
  font-size : 40px;
}

h3 {
  color       : $black;
  font-size   : 28px;
  line-height : 36px;
}

h4 {
  color       : $black;
  font-size   : 28px;
  line-height : 36px;
}

h5 {
  color       : $black;
  font-size   : 15px;
  font-weight : bold;
}

.cms,
p {
  color       : $black;
  font-size   : 16px;
  line-height : 22px;

  a {
    color           : $green;
    text-decoration : underline;
    font-weight     : bold;

    &:hover {
      color           : darken($green, 10%);
      text-decoration : none;
    }
  }
}

// ==========[ HEADER / NAVIGATIE ]==========

.top-bar {
  z-index          : 999;
  display          : flex;
  position         : fixed;
  top              : 0;
  right            : 0;
  left             : 0;
  padding          : 15px;
  border-bottom    : 2px solid $grey;
  background-color : $white;
  align-items      : center;
  justify-content  : center;

  a {
    margin-right : 32px;
    color        : $grey-dark;
    font-size    : 15px;

    &.active,
    &:hover {
      color : #000000;
    }

    &.active {
      font-weight : bold;
    }

    &:last-child {
      margin-right : 0;
    }
  }
}

.navbar {
  margin-top       : 54px;
  padding          : 50px 0;
  background-color : $white;

  .navbar-toggler{
    &:hover{
      svg{
        color: $green;
      }
    }
  }

  .nav-item {
    .nav-link {
      color       : $black;
      font-size   : 0.92em;
      font-weight : bold;
    }

    &:hover {
      .nav-link {
        color : $green;
      }
    }
  }

  .talen {
    display     : flex;
    align-items : center;
    text-align: center;
    a{
      border: 1px solid $grey-dark;
      border-left: none;
      padding: 14px 14px;
      color        : $grey-dark;
      &.active,
      &:hover {
        color : $black;
      }
    }
    li{
      &:first-child{
        a{
          border-left: 1px solid $grey-dark;
        }
      }
    }
  }
}

// ==========[ FOOTER ]==========

footer {
  background-color : transparentize(#F0F0F0, .3);

  h4 {
    font-size   : 18px;
    font-weight : bold;
  }

  .sub-footer {
    display         : flex;
    margin-top      : 30px;
    padding-top     : 30px;
    border-top      : 2px solid #E3E3E3;
    align-items     : center;
    justify-content : space-between;

    p {
      color       : $black;
      font-size   : 14px;
      font-weight : bold;

      a {
        color : $black;

        &:hover {
          color           : $green;
          text-decoration : none;
        }
      }
    }
  }
}

.back__to__top__button {
  @include           quart-transition(400ms);
  display          : flex;
  position         : fixed;
  right            : 20px;
  bottom           : 20px;
  width            : 50px;
  height           : 50px;
  color            : #FFF;
  border-radius    : 999px;
  background-color : $green;
  align-items      : center;
  font-size        : 18px;
  justify-content  : center;
  opacity: 0;
  
  &:hover {
    @include           softshadow;
    color            : darken($green, 8%);
    background-color : #FFF;
    cursor           : pointer;
  }
}

.show{
  opacity : 1;
}


.edit__page__button {
  @include           quart-transition(400ms);
  display          : flex;
  position         : fixed;
  right            : 75px;
  bottom           : 20px;
  width            : 50px;
  height           : 50px;
  color            : #FFF;
  border-radius    : 999px;
  background-color : $grey-dark;
  align-items      : center;
  font-size        : 18px;
  justify-content  : center;

  &:hover {
    @include           softshadow;
    color            : $grey;
    background-color : #FFF;
  }
}

// ==========[ HEADER CTA ]==========

.header-cta {
  min-height          : 600px;
  background-repeat   : no-repeat;
  background-position : center top;
  background-size     : cover;

  .overlay {
    height           : 600px;
    padding-top      : 50px;
    padding-bottom   : 200px;
    background-color : transparentize($black, .8);

    .container {
      position : relative;
      height   : 100%;

      .row {
        height      : 100%;
        align-items : flex-end;
      }
    }

    .logo {
      position  : absolute;
      max-width : 200px;

      img {
        width : 100%;
      }

      &.top-right {
        top   : 0;
        right : 0;
      }

      &.top-left {
        top  : 0;
        left : 0;
      }

      &.bottom-right {
        right  : 0;
        bottom : 0;
      }

      &.bottom-left {
        bottom : 0;
        left   : 0;
      }
    }
  }
}

.header-boxes {
  margin-top : -100px;

  .header-box {
    padding       : 50px 65px;
    border-radius : 50px;
    box-shadow    : 0px 10px 30px -10px rgba(0,0,0,.25);
    transition    : 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

    .cms,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color : $white;
    }

    p {
      color : $white;
    }

    &.grey {
      background-color : $black;
    }

    &.green {
      background-color : $green;

      .btn-secondary {
        color : $green !important;
      }
    }

    &:hover {
      box-shadow : 0px 20px 30px -15px rgba(0,0,0,0.25);
      transform  : scale(1.025);
    }
  }
}

// ==========[ RIGHT SIDE SLIDER ]==========

.half-screen-slider {
  position : relative;

  .images-slider {
    position : absolute;
    right    : 0;
    bottom   : -50px;
    width    : 50vw;
    height   : 500px;

    .image-slide {
      position            : relative;
      width               : 100%;
      height              : 500px;
      background-repeat   : no-repeat;
      background-position : center;
      background-size     : cover;

      .locatie {
        display          : flex;
        position         : absolute;
        right            : 0;
        bottom           : 0;
        padding          : 7px 20px;
        color            : $white;
        background-color : $green;
        align-items      : center;
        justify-content  : flex-start;

        p {
          color : $white;
        }
      }
    }
  }
}

// ==========[ TROEVEN ]==========

.troeven {
  display        : flex;
  align-items    : flex-start;
  flex-direction : column;

  .troef {
    display         : flex;
    margin-bottom   : 10px;
    align-items     : center;
    justify-content : flex-start;

    i,
    svg {
      margin-right : 20px;
      color        : $green;
      font-size    : 20px;
    }
  }
}

// ==========[ GEHOORBESCHERMING ]==========
.gehoorbescherming{
  span{
    color : $green !important;
  }
}


// ==========[ PRODUCTEN ]==========

.product-item {
  .image {
    width               : 100%;
    padding-top         : 75%;
    border              : 2px solid $grey;
    background-repeat   : no-repeat;
    background-position : center;
    background-size     : auto 80%;
  }

  .content {
    display         : flex;
    margin-top      : 20px;
    align-items     : flex-start;
    justify-content : space-between;

    .left {
      flex : 2 0 66%;
    }

    .right {
      text-align : right;
      flex       : 1 0 33%;
    }

    .prijs {
      color       : #999;
      font-size   : 17px;
      font-weight : bold;
    }
  }
}

// ==========[ TEAM ]==========

.teamlid {
  display         : flex;
  width           : 100%;
  height          : 525px;
  border          : 2px solid $grey;
  align-items     : flex-start;
  justify-content : space-between;
  transition      : 450ms cubic-bezier(0.860, 0.000, 0.070, 1.000);

  section {
    display         : flex;
    width           : 60%;
    height          : 100%;
    padding         : 40px;
    flex-direction  : column;
    justify-content : space-between;
  }

  .teamlid-foto {
    position            : absolute;
    top                 : -10%;
    right               : 17px;
    width               : 52%;
    height              : 110%;
    background-repeat   : no-repeat;
    background-position : left;
    background-size     : cover;
  }

  h2 {
    color       : $green;
    font-weight : bold;
  }

  h3 {
    margin      : 12px 0 0;
    color       : #999999;
    font-size   : 15px;
    font-weight : 700;
    line-height : 18px;
  }

  &:hover {
    background-color : $grey;
  }
}

.teamlid-mobile {
  display         : flex;
  margin-bottom   : 50px;
  border          : 2px solid $grey;
  align-items     : flex-start;
  flex-direction  : column;
  justify-content : flex-start;

  .upper {
    display         : flex;
    width           : 100%;
    align-items     : flex-end;
    flex-direction  : row;
    justify-content : space-between;
  }

  .down {
    display : none;
    padding : 15px;
  }

  .left {
    max-width : calc(100% - 150px);
    padding   : 15px;
  }

  .right {
    display         : flex;
    align-items     : flex-end;
    justify-content : flex-end;

    .teamlid-foto {
      width               : 150px;
      height              : 200px;
      margin-top          : -50px;
      background-position : top left;
      background-size     : cover;
    }
  }

  h2 {
    font-size   : 22px;
    font-weight : bold;
  }

  h3 {
    margin      : 12px 0 0;
    color       : #999999;
    font-size   : 15px;
    font-weight : 700;
    line-height : 18px;
  }
}

// ==========[ GETUIGENISSEN ]==========

.getuigenissen-slider {
  display         : flex;
  align-items     : flex-start;
  justify-content : space-between;

  .getuigenis {
    display         : flex;
    align-items     : center;
    flex            : 0 1 30%;
    flex-direction  : column;
    justify-content : center;

    .avatar {
      width               : 165px;
      height              : 165px;
      margin-bottom       : 30px;
      border-radius       : 999px;
      background-repeat   : no-repeat;
      background-position : center;
      background-size     : cover;
    }

    h5 {
      color       : #999;
      text-align  : center;
      font-size   : 16px;
      font-weight : 400;
    }

    h4 {
      color       : $black;
      text-align  : center;
      font-size   : 17px;
      font-weight : bold;
    }

    .cms {
      text-align : center;
    }

    .rating {
      color : $green;
    }
  }
}

// ==========[ In de kijker ]==========
.offer{
  width: 100%;
  text-align: center;
  background-color: $green;
  padding: 10px 0;
  h4{
    color: $white;
  }
}